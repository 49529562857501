import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { darken } from 'polished';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import yellow from '@material-ui/core/colors/yellow';
import { Layout, Navigation, Article, Wrapper, SectionTitle, Media, Video, Alex, THHNContent, THHNClosed } from '../components';
import Logo from '../assets/img/logo.svg';
import Ripple from '../assets/img/ripple.svg';
import '../assets/type/fonts.css';

const Hero = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0 0;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0.5rem;
  }

  h1 {
    background: url("/img/thhn-logo.png") center center no-repeat;
    background-size: cover;
    height: 300px;
    text-indent: -9999px;
    width: 500px;
  }
`

const Content = styled.div`
  grid-column: 2;
  margin-top: -1.5rem;
  padding: 3rem 6rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;

  img, svg {
    max-width: 100%;
  }
`

const TheHappyHealthyNurse = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscriber, setSubscriber] = useState(false);
  const [failure, setFailure] = useState(false);

  const mcFreeEbook = (ev) => {
    setSubscribe(!subscribe)
    setLoading(true)

    let postObj = {
      email_address: email,
      status: "subscribed",
      tags: ["Main"],
      merge_fields: {
        "FNAME": firstName
      }
    };

    fetch('https://selfdevlab.com/.netlify/functions/mailchimp', {
      method: 'POST',
      body: JSON.stringify(postObj),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
      .then(response => {
        console.log(response)
        if (response.status === 400 && response.title === "Member Exists") {
          setLoading(false)
          setSubscriber(!subscriber)
          console.log('Success:', JSON.stringify(response))
        } else {
          setLoading(false)
          setSuccess(!success)
        }
      })
      .catch(error => {
        setLoading(false)
        setFailure(!failure)
        console.error('Error:', error)
      });
  }

  return (
    <Layout>
      {isOpen &&
        <THHNContent />
      }
      {!isOpen &&
        <THHNClosed />
      }
    </Layout>
  )
}

export default TheHappyHealthyNurse

